import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './config/i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/index.scss'
import '../src/icons'
import './utils/rem'

Vue.prototype.$store = store
import { postLoginRequest } from './utils/request'
Vue.prototype.postLoginRequest = postLoginRequest
// Vue.prototype.$http = 'https://www.longsqin.com/source/image'
// Vue.prototype.$movie = 'https://www.longsqin.com/source/product'
Vue.prototype.$http = 'https://www.longsqin.cn/source/image'
Vue.prototype.$movie = 'https://www.longsqin.cn/source/product'

Vue.use(ElementUI);

// router.beforeEach((to, from, next)=>{
//   console.log(to);
//   console.log(from);
//   next()
// })

// 路由跳转滚动条回滚到顶部
router.afterEach(()=>{
  window.scrollTo(0, 0)
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
